.message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.message-bubble {
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    max-width: 80%;
    /* Reduziere die maximale Breite */
    width: fit-content;
    /* Füge die Breite des Inhalts hinzu */
    text-align: left;
    /* Linksbündiger Text */
}

.message-bubble.other {
    align-self: flex-start;
    margin-right: auto;
    /* Rechter Rand automatisch setzen */
}

.message-bubble.self {
    align-self: flex-end;
    background-color: #dcf8c6;
    text-align: left;
    /* Linksbündiger Text */
    margin-left: auto;
    /* Linker Rand automatisch setzen */
}
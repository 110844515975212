/* Stil für den Haupttitel */
h1 {
    color: #333;
    font-size: 2rem;
    margin-bottom: 30px;
}

/* Stil für die Formularfelder */
.form-group {
    margin-bottom: 20px;
}

/* Stil für das Label */
.form-label {
    color: #555;
}

/* Stil für den Submit-Button */
.btn-primary {
    width: 100%;
    background-color: #28a745;
    /* Grüne Farbe */
    border-color: #28a745;
    font-size: 1.2rem;
    margin-top: 10px;
    /* Abstand zum vorherigen Element */
}

.btn-primary:hover {
    background-color: #218838;
    /* Dunklere grüne Farbe */
    border-color: #1e7e34;
}

/* Stil für die Erfolgsmeldung */
.alert-success {
    margin-top: 20px;
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

/* Stil für die Fehlermeldung */
.alert-danger {
    margin-top: 20px;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

/* PostView.css */